<script setup lang="ts">
import { Navigation, Grid, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import type { Swiper as SwiperTypes } from "swiper/types";
import type { Article } from "~/models/Article";
import { routes } from "~/constants";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

const { isDesktop } = useDevice();
const { sliderArticles } = defineProps<{
  sliderArticles: Article[];
}>();

const modules = [Navigation, Grid, Pagination];
const slider = ref();

const onSwiper = (swiper: SwiperTypes) => {
  slider.value = swiper;
};
const navigationOptions = {
  nextEl: ".arrow-right",
  prevEl: ".arrow-left",
};
</script>

<template>
  <Swiper
    :navigation="navigationOptions"
    wrapper-class="extra-slider carousel-banner"
    :pagination="{
      clickable: true,
    }"
    :modules="modules"
    :space-between="12"
    @swiper="onSwiper"
  >
    <button v-if="isDesktop" class="arrow-left arrow" role="button">&nbsp;</button>

    <swiper-slide
      v-for="(currentArticle, index) in sliderArticles"
      :key="index"
      :style="{
        background: `url(${currentArticle.banner.url}) center / cover`,
      }"
    >
      <NuxtLink
        :to="`${routes.blogHomeUrl}/articles/${currentArticle.slug}`"
        class="carousel-banner-item"
      >
        <div class="carousel-banner-infos">
          <div class="carousel-banner-infos-container">
            <div class="carousel-banner-infos-top">
              <ArticleInfo
                :date-of-publication="currentArticle.publishedAt"
                :duration="calculateReadingTime(currentArticle.content)"
                :author-fullname="currentArticle.author"
                color="#fff"
              />
            </div>
            <div class="carousel-banner-infos-middle">
              <h1 v-if="index === 0">
                {{ currentArticle.title }}
              </h1>
              <h2 v-else>
                {{ currentArticle.title }}
              </h2>
              <p v-if="currentArticle.smallDescription">
                {{ sanitize(currentArticle.smallDescription) }}
              </p>
            </div>
            <div class="carousel-banner-infos-bottom">
              <ButtonTag
                v-for="tag in currentArticle.tags.slice(0, 4)"
                :key="tag.id"
                :href="`${routes.blogHomeUrl}/${tag.slug}/1`"
                :tag-value="tag.title"
                type="secondary"
              />
            </div>
          </div>
        </div>
      </NuxtLink>
    </swiper-slide>
    <button v-if="isDesktop" class="arrow-right arrow" role="button">&nbsp;</button>
  </Swiper>
</template>

<style lang="scss" scoped>
.carousel-banner {
  width: 100%;
  position: relative;
  margin-bottom: $grid-spacer;
  height: 100%;
  &-item {
    width: 100%;
    min-height: 560px;
    display: flex;
    cursor: pointer;
  }
  &-infos {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    padding: $grid-padding $grid-padding 50px $grid-padding;
    color: $white;
    &-container {
      width: $grid-full-width;
      margin: 0 auto;
      @include mq(desktop) {
        width: $grid-max-width;
        padding: 0 var(--showcase-size-100);
      }
    }
    &-top {
      margin-bottom: 10px;
    }
    &-middle {
      margin-bottom: 20px;
      h1,
      h2 {
        font-weight: 800;
        margin-bottom: 15px;
      }
    }
    &-bottom {
      display: flex;
    }
    &-slider {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
    .rect {
      height: 5px;
      width: 45px;
      border-radius: $border-radius-6;
      background-color: $grey-medium;
      &.active {
        background-color: $primary-color;
      }
    }
  }
}
.arrow {
  display: block;
  width: 53px;
  height: 53px;
  background: url("/img/arrow-slider-left.svg") no-repeat;
  position: absolute;
  top: calc(50% - 25px);
  background-size: 90%;
  z-index: 1;
  &:hover {
    background: url("/img/arrow-slider-left-hover.svg") no-repeat;
    background-size: 90%;
  }
  &-left {
    left: var(--showcase-size-100);
  }
  &-right {
    right: var(--showcase-size-100);
    background: url("/img/arrow-slider-right.svg") no-repeat;
    background-size: 90%;
    &:hover {
      background: url("/img/arrow-slider-right-hover.svg") no-repeat;
      background-size: 90%;
    }
  }
  &.swiper-button-disabled {
    display: none;
  }
}
</style>
